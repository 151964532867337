import { Typography } from '@carto/meridian-ds/components'
import { Box, Button, styled } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import Prompt from 'app/ui/Prompt'

import mapPlaceholderImage from 'assets/img/map-placeholder.webp'
import { redirectToAccounts } from 'features/auth-redirect/api/authenticationUtils'

const Background = styled(Box)(() => ({
  width: '100%',
  height: '100vh',
  background: `url('${mapPlaceholderImage}') lightgray 50% / cover no-repeat`,
  '&:before': {
    content: "''",
    display: 'block',
    height: '100vh',
    width: '100%',
    backdropFilter: 'blur(8px)'
  }
}))

function BuilderStorageAccessPrompt({ onRequestAccess, accessWasDenied }) {
  return (
    <>
      <Background />
      <Prompt
        title={
          <FormattedMessage
            id={accessWasDenied ? 'builder.cookiesPrompt.titleDenied' : 'builder.cookiesPrompt.title'}
          />
        }
      >
        <Box px={2} flex={1}>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage
              id={accessWasDenied ? 'builder.cookiesPrompt.contentDenied' : 'builder.cookiesPrompt.content'}
            />
          </Typography>
        </Box>
        <Box p={2} textAlign="right">
          {accessWasDenied && (
            <Button onClick={() => redirectToAccounts()}>
              <FormattedMessage id="builder.cookiesPrompt.goToCarto" />
            </Button>
          )}
          <Button variant="contained" onClick={onRequestAccess}>
            <FormattedMessage id={accessWasDenied ? 'common.button.retry' : 'common.button.allow'} />
          </Button>
        </Box>
      </Prompt>
    </>
  )
}

export default BuilderStorageAccessPrompt
